body {
	margin: 0;
	background-color: #000;
	color: #fff;
	overscroll-behavior: none;
	overflow: hidden;
	font-family: sans-serif;
}
#loader {
	border-radius: 10px;
	background-color: rgba(255,255,255,0.1);
	width: 40vw;
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	-webkit- transform: translateY(-50%) translateX(-50%);
	vertical-align: middle;
	font-size: 2vw;
	text-align: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.loader,
.loader:after {
	border-radius: 50%;
	width: 5vw;
	height: 5vw;
}
.loader {
	margin: 0px auto;
	margin-top: 1em;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1vw solid rgba(255, 255, 255, 0.2);
	border-right: 1vw solid rgba(255, 255, 255, 0.2);
	border-bottom: 1vw solid rgba(255, 255, 255, 0.2);
	border-left: 1vw solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 0.7s infinite linear;
	animation: load8 0.7s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.btn {
	background-color: rgba(255, 255, 255, 0.8);
	position: absolute;
	height: 8vw;
	width: 30vw;
	border-radius: 6vw;
	font-size: 2.5vw;
	font-weight: bold;
	color: black;
	line-height: 8vw;
	padding: 0 2vw 0 2vw;
}
.btn_area {
	position: absolute;
	border-radius: 6vw;
	background-color: rgba(255, 255, 255, 0.1);
	border: 0.5vw solid rgba(255, 255, 255, 0.8);
}